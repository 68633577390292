import { AuthenticationService } from './authentication.service';
import { User } from './../models/api.user';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ResourceType } from './../models/api.resource_type';
import { Resource } from './../models/api.resource';
import { ConfigurationService } from './configuration.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { Grade } from '../models/api.grade';

@Injectable()
export class ApiService {

    public resources: BehaviorSubject<Resource[]> = new BehaviorSubject<Resource[]>([]);
    public resource_types: BehaviorSubject<ResourceType[]> = new BehaviorSubject<ResourceType[]>([]);
    public user: BehaviorSubject<User> = new BehaviorSubject<User>(new User());
    constructor(
        private http: HttpClient,
        private configurationService: ConfigurationService,
        private authenticationService: AuthenticationService
    ) {
        // this.http.get(this.configurationService.apiUrl + '/api/resources').subscribe((response: Resource[]) => {
        //     this.resources.next(response);
        // });

        this.http.get(this.configurationService.apiUrl + '/api/resource_types').subscribe((response: ResourceType[]) => {
            this.resource_types.next(response);
        });

        this.authenticationService.isAuthenticated.subscribe((result: boolean) => {
            if (result === true && this.authenticationService.getDecodedAccessToken()) {
                const userToken = this.authenticationService.getDecodedAccessToken();
                this.getUser(userToken.id);
            }
        });
    }

    private getUser(id: string) {
        this.http.get(this.configurationService.apiUrl + '/api/users/' + id).subscribe((response: User) => {
            this.user.next(response);
        });
    }

    public getResource(id: string): Observable<Resource> {
        return this.http.get(this.configurationService.apiUrl + '/api/resources/' + id).map((object: Resource) => {
            return object;
        });
    }

    public addActivity(activity: {title: string, value: string}): void {
        const userToken = this.authenticationService.getDecodedAccessToken();
        activity['user'] = '/api/users/' + userToken.id;
        this.http.post(this.configurationService.apiUrl + '/api/activities', activity).subscribe((response: Response) => {
            // console.log('activity created');
            this.getUser(userToken.id);
        });
    }

    public getGradingUri(resource: Resource): string {
        const user_token = this.authenticationService.getDecodedAccessToken();
        const raw_token = this.authenticationService.getRawToken();
        return '?host=' + this.configurationService.apiUrl + '/api/grades'
            + '&resource_uri=' + '/api/resources/' + resource.record_id
            + '&user_uri=' + '/api/users/' + user_token.id
            + '&token=' + raw_token;
    }

    public addGrade(grade: {resource: Resource, value: string}): void {
        const userToken = this.authenticationService.getDecodedAccessToken();
        const new_grade = new Grade();
        new_grade.resource = '/api/resources/' + grade.resource.record_id;
        new_grade.user = '/api/users/' + userToken.id;
        new_grade.value = grade.value;
        new_grade.title = 'Online Grading';
        this.http.post(this.configurationService.apiUrl + '/api/grades', new_grade).subscribe((response: Response) => {
            // console.log('grade created');
            this.getUser(userToken.id);
        });
    }

}
