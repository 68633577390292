import { Resource } from './../../models/api.resource';
import { User } from './../../models/api.user';
import { ApiService } from './../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-resource',
    templateUrl: './resource.component.html',
    styleUrls: ['./resource.component.scss']
})
export class ResourceComponent implements OnInit {

    public user: User;
    public resource: Resource = new Resource();
    public grading_uri = '';
    constructor(
        private configurationService: ConfigurationService,
        private apiService: ApiService,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit() {

        this.route.paramMap.pipe(
            switchMap((params: ParamMap) =>
            this.apiService.getResource(params.get('id')))
          ).subscribe((resource: Resource) => {
              this.resource = resource;
              this.apiService.addActivity({title: 'progress', value: 'user has viewed resource ' + this.resource.title });
              this.grading_uri = this.apiService.getGradingUri(this.resource);
              this.configurationService.setTitle(this.resource.title);
            //   this.apiService.addGrade({resource: resource, value: '5/10' });
          });

        this.apiService.user.subscribe((user: User) => {
            this.user = user;
        });

    }

}
