import { UtilitiesService } from './../../services/utilities.service';
import { Component, OnInit } from '@angular/core';
import * as ol from 'openlayers';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    constructor(
        private configurationService: ConfigurationService,
        private utilitiesService: UtilitiesService
    ) { }

    public model: any = {};
    public submit_result: string;
    public loading = false;

    onSubmit() {
        this.loading = true;
        this.submit_result = undefined;
        this.utilitiesService.contact(this.model)
            .first()
            .subscribe(
                (response: any) => {
                    if (typeof response.code !== 'undefined' && response.code === 200) {
                        this.submit_result = 'Thanks, we will be in touch shortly';
                    } else {
                        this.submit_result = response.message;
                    }
                    // console.log('POST call successful value returned in body', response);
                    this.loading = false;
                },
                response => {
                    this.submit_result = 'Sorry this failed because of a server error';
                    // console.log('POST call in error', response);
                    this.loading = false;
                });
    }

    ngOnInit() {
        this.configurationService.setTitle('Contact Us');

        const map = new ol.Map({
            target: 'contactMap',
            layers: [
                new ol.layer.Tile({
                    source: new ol.source.OSM()
                })
            ],
            view: new ol.View({
                center: ol.proj.fromLonLat([176.185104, -37.729431]),
                zoom: 15
            }),
            controls: []
        });

        map.updateSize();

    }

}
