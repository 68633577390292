import { User } from './../../models/api.user';
import { ApiService } from './../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    public user: User;
    constructor(
        private configurationService: ConfigurationService,
        private apiService: ApiService
    ) { }

    ngOnInit() {
        this.configurationService.setTitle('Dashboard');

        this.apiService.user.subscribe((user: User) => {
            this.user = user;
        });
    }

}
