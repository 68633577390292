import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';
import * as d3 from 'd3';
// import {scaleLinear} from 'd3-scale';


@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit, AfterViewInit {

  @ViewChild('progressCont') progressCont: ElementRef;

  constructor(private configurationService: ConfigurationService) { }

  ngAfterViewInit() {
    const data = [
      { 'salesperson': 'Bob', 'sales': 33 },
      { 'salesperson': 'Robin', 'sales': 12 },
      { 'salesperson': 'Anne', 'sales': 41 },
      { 'salesperson': 'Mark', 'sales': 16 },
      { 'salesperson': 'Joe', 'sales': 59 },
      { 'salesperson': 'Eve', 'sales': 38 },
      { 'salesperson': 'Karen', 'sales': 21 },
      { 'salesperson': 'Kirsty', 'sales': 25 },
      { 'salesperson': 'Chris', 'sales': 30 },
      { 'salesperson': 'Lisa', 'sales': 47 },
      { 'salesperson': 'Tom', 'sales': 5 },
      { 'salesperson': 'Stacy', 'sales': 20 },
      { 'salesperson': 'Charles', 'sales': 13 },
      { 'salesperson': 'Mary', 'sales': 29 }
    ];

    const margin = {top: 0, right: 0, bottom: 20, left: 0},
    width = this.progressCont.nativeElement.clientWidth - margin.left - margin.right,
    height = 100 - margin.top - margin.bottom;

    // set the ranges
    const x = d3.scaleBand()
              .range([0, width])
              .padding(0.1);
    const y = d3.scaleLinear()
              .range([height, 0]);

    // append the svg object to the body of the page
    // append a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    const svg = d3.select('.container-bar').append('svg')
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .attr('viewBox', '0 0 ' + width + ' 100')
      .append('g')
        .attr('transform',
              'translate(' + margin.left + ',' + margin.top + ')');

    // format the data
    data.forEach(function(d) {
      d.sales = +d.sales;
    });

    // Scale the range of the data in the domains
    x.domain(data.map(function(d) { return d.salesperson; }));
    y.domain([0, d3.max(data, function(d) { return d.sales; })]);

    // append the rectangles for the bar chart
    svg.selectAll('.bar')
        .data(data)
      .enter().append('rect')
        .attr('class', 'bar')
        .attr('x', function(d) { return x(d.salesperson); })
        .attr('width', x.bandwidth())
        .attr('y', function(d) { return y(d.sales); })
        .attr('height', function(d) { return height - y(d.sales); });

    // add the x Axis
    svg.append('g')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(x));

    // // add the y Axis
    // svg.append('g')
    //     .call(d3.axisLeft(y));
  }

  ngOnInit() {
    this.configurationService.setTitle('Your Progress');
  }

}
