import { ConfigurationService } from './configuration.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import * as jwt_decode from 'jwt-decode';

@Injectable()
export class AuthenticationService {

    public isAuthenticated: BehaviorSubject<boolean>;
    
    constructor(
        private http: HttpClient,
        private configurationService: ConfigurationService
    ) {
        if (localStorage.getItem('currentUser')) {
            this.isAuthenticated = new BehaviorSubject<boolean>(true);
        } else {
            this.isAuthenticated = new BehaviorSubject<boolean>(false);
        }
    }

    login(username: string, password: string) {
        return this.http.post<any>(this.configurationService.apiUrl + '/api/getToken', { username: username, password: password })
            .map(token => {
                // login successful if there's a jwt token in the response
                if (token && token.token) {
                    // store token details and jwt token in local storage to keep token logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(token));
                    this.isAuthenticated.next(true);
                }

                return token;
            });
    }

    getRawToken(): any|false {
            const token = JSON.parse(localStorage.getItem('currentUser'));
            return token.token;
    }
    getDecodedAccessToken(): any|false {
        try {
            const token = JSON.parse(localStorage.getItem('currentUser'));
            return jwt_decode(token.token);
        } catch (Error) {
            return false;
        }
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.isAuthenticated.next(false);
    }
}
