import { Alert } from './../../models/alert';
import { Component, OnInit } from '@angular/core';

import { AlertService } from '../../services/alert.service';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
    public alerts: Array<Alert> = [];
    constructor(
        private alertService: AlertService
    ) { }

    ngOnInit() {
        this.alertService.latestAlert.subscribe((alert: Alert) => {
            if (typeof alert !== 'undefined') {
                this.alerts.push(alert);
            }
        });
    }

}
