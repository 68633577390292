import { Alert } from './../models/alert';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class AlertService {
    public latestAlert: BehaviorSubject<Alert> = new BehaviorSubject<Alert>(undefined);
    addAlert(level: string, message: string) {
        const alert: Alert = new Alert();
        alert.level = level;
        alert.message = message;
        this.latestAlert.next(alert);
    }

}
