import { Router } from '@angular/router';
import { ApiService } from './../../services/api.service';
import { AuthenticationService } from './../../services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { User } from '../../models/api.user';

@Component({
    selector: 'app-manage',
    templateUrl: './manage.component.html',
    styleUrls: ['./manage.component.scss']
})
export class ManageComponent implements OnInit {

    public authenticated = false;
    public user: User;
    constructor(
        private authenticationService: AuthenticationService,
        private apiService: ApiService,
        private router: Router
    ) { }

    ngOnInit() {
        this.authenticationService.isAuthenticated.subscribe((result: boolean) => {
            this.authenticated = result;
            if (result === true) {
                this.apiService.user.subscribe((user: User) => {
                    this.user = user;
                });
            }
        });
    }

    logout() {

        this.apiService.addActivity({title: 'authentication', value: 'user has signed out'});
        this.authenticationService.logout();
        this.router.navigate(['login']);
    }

}
