import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class ConfigurationService {

  public siteName = 'Akoranga - MGPI';
  public siteTitle = 'mGPI accounting socialisation course materials';
  public apiUrl = 'https://akoranga-api.mgpi.co.nz';

  constructor(private title: Title) { }

  setTitle(newTitle: string) {
    this.title.setTitle(this.siteName + ' - ' + newTitle);
  }

}
