import { ApiService } from './../../services/api.service';
import { UtilitiesService } from './../../services/utilities.service';
import { ConfigurationService } from './../../services/configuration.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/first';

import { AuthenticationService } from '../../services/authentication.service';

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
    model: any = {};
    loading = false;
    login_submit_result = '';
    reset_submit_result = '';
    register_submit_result = '';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private configurationService: ConfigurationService,
        private utilitiesService: UtilitiesService,
        private apiService: ApiService
    ) { }

    ngOnInit() {
        // reset login status
        this.authenticationService.logout();

        this.configurationService.setTitle('Sign In');
    }

    register() {
        this.loading = true;
        this.utilitiesService.register(this.model)
            .subscribe(
                (response: any) => {
                    if (typeof response.code !== 'undefined' && response.code === 200) {
                        this.register_submit_result = 'Thanks, we will be in touch shortly';
                    } else {
                        this.register_submit_result = response.message;
                    }
                    // console.log('POST call successful value returned in body', response);
                    this.loading = false;
                },
                response => {
                    this.register_submit_result = 'Sorry this failed because of a server error';
                    // console.log('POST call in error', response);
                    this.loading = false;
                });
    }

    login() {
        this.loading = true;
        this.authenticationService.login(this.model.username, this.model.password)
            .subscribe(
                data => {
                    this.apiService.addActivity({title: 'authentication', value: 'user has signed in'});
                    this.router.navigate(['account']);

                },
                error => {
                    console.log(error);
                    this.login_submit_result = error.message;
                    this.loading = false;

                });
    }

    reset() {
        this.loading = true;
        this.utilitiesService.resetPassword(this.model)
            .subscribe(
                (response: any) => {
                    if (typeof response.code !== 'undefined' && response.code === 200) {
                        this.reset_submit_result = 'Thanks, we will be in touch shortly';
                    } else {
                        this.reset_submit_result = response.message;
                    }
                    // console.log('POST call successful value returned in body', response);
                    this.loading = false;
                },
                response => {
                    this.reset_submit_result = 'Sorry this failed because of a server error';
                    // console.log('POST call in error', response);
                    this.loading = false;
                });
    }

}
