import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public title;
  public currentDate;

  constructor(private configurationService: ConfigurationService) {

  }

  ngOnInit() {
    this.title = this.configurationService.siteName;
    this.currentDate = (new Date()).getFullYear();
  }

}
