import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-login-alert',
  templateUrl: './login-alert.component.html',
  styleUrls: ['./login-alert.component.scss']
})
export class LoginAlertComponent implements OnInit {

  ngOnInit() {
  }

  decodedOutput = (event) => {
    console.log(event);

    // call api here with authentication request
  }

}
