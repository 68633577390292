import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'collapseText' })
export class CollapseTextPipe implements PipeTransform {
    transform(value: any, args: string[]): any {

        value = value.toLowerCase();
        value = value.replace(' ', '');
        return value;
    }
}
