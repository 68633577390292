import { ApiService } from './../../services/api.service';
import { Router } from '@angular/router';
import { AuthenticationService } from './../../services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    public title;
    public isAuthenticated = false;
    constructor(
        private configurationService: ConfigurationService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private apiService: ApiService

    ) {
        this.authenticationService.isAuthenticated.subscribe((result: boolean) => {
            this.isAuthenticated = result;
        });
    }

    ngOnInit() {
        this.title = this.configurationService.siteTitle;
    }

    logout() {

        this.apiService.addActivity({title: 'authentication', value: 'user has signed out'});
        this.authenticationService.logout();
        this.router.navigate(['login']);
    }

}
