import { AuthenticationService } from './authentication.service';
import { AlertService } from './alert.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private alertService: AlertService,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const tokenObj: any = this.authenticationService.getDecodedAccessToken();

        // check if current token
        if (tokenObj && (new Date()).getTime() > tokenObj.exp) {
            return true;
        }

        // not logged in or token expired
        this.alertService.addAlert('warning', 'Your session has expired, please <a href="login">log in</a> again');
        this.authenticationService.logout();
        return false;
    }
}
