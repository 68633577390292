// Services
import { ApiService } from './services/api.service';
import { UtilitiesService } from './services/utilities.service';
import { ConfigurationService } from './services/configuration.service';
import { PopupService } from './services/popup.service';
import { AlertService } from './services/alert.service';
import { AuthenticationService } from './services/authentication.service';

// Angular Services
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// Structure
import { AppComponent } from './app.component';
import { HeaderComponent } from './structure/header/header.component';
import { FooterComponent } from './structure/footer/footer.component';
import { ManageComponent } from './structure/manage/manage.component';

// Partials
import { PopupComponent } from './partials/popup/popup.component';
import { AlertComponent } from './partials/alert/alert.component';
import { LoginAlertComponent } from './partials/login-alert/login-alert.component';

// Pages
import { InfoComponent } from './pages/info/info.component';
import { LoginComponent } from './pages/login/login.component';
import { SplashComponent } from './pages/splash/splash.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ShowcaseComponent } from './pages/showcase/showcase.component';
import { ResourceComponent } from './pages/resource/resource.component';
import { ResourcesComponent } from './pages/resources/resources.component';

// Internal Pages
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { StoryComponent } from './pages/story/story.component';
import { HistoryComponent } from './pages/history/history.component';
import { ProgressComponent } from './pages/progress/progress.component';
import { AccountComponent } from './pages/account/account.component';
import { ScoreboardComponent } from './pages/scoreboard/scoreboard.component';

// Utils
import { ObjectKeysPipe } from './pipes/objectKeys';
// import { QrScannerModule } from 'angular2-qrscanner/dist';
import { AuthGuard } from './services/auth.guard';
import { JwtInterceptor } from './services/jwt.interceptor';
import { CollapseTextPipe } from './pipes/collapseText';
import { SafeStringPipe } from './pipes/safeString';
import { EvalStringPipe } from './pipes/evalString';


const routes: Routes = [
    {
        path: 'resource/:id',
        component: ResourceComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'account',
        component: AccountComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'progress',
        component: ProgressComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'scoreboard',
        component: ScoreboardComponent,
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'resources',
    //     component: ResourcesComponent,
    // },
    // {
    //     path: 'showcase',
    //     component: ShowcaseComponent,
    // },
    // {
    //     path: 'splash',
    //     component: SplashComponent,
    // },
    // {
    //     path: 'story',
    //     component: StoryComponent,
    // },
    {
        path: 'history',
        component: HistoryComponent,
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'info',
    //     component: InfoComponent,
    // },
    // {
    //     path: 'contact',
    //     component: ContactComponent,
    // },
    {
        path: '**',
        component: LoginComponent,
    }
];

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        SplashComponent,
        LoginComponent,
        PopupComponent,
        AlertComponent,
        LoginAlertComponent,
        ResourcesComponent,
        DashboardComponent,
        StoryComponent,
        HistoryComponent,
        ProgressComponent,
        InfoComponent,
        ContactComponent,
        ShowcaseComponent,
        AccountComponent,
        ScoreboardComponent,
        ManageComponent,
        ResourceComponent,

        ObjectKeysPipe,
        SafeStringPipe,
        CollapseTextPipe,
        EvalStringPipe
    ],
    imports: [
        RouterModule.forRoot(routes, { useHash: true }),
        HttpClientModule,
        BrowserModule,
        ReactiveFormsModule,
        FormsModule
        // QrScannerModule
    ],
    providers: [
        ConfigurationService,
        PopupService,
        AlertService,
        AuthGuard,
        AuthenticationService,
        UtilitiesService,
        ApiService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        Title
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
