import { ConfigurationService } from './configuration.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable()
export class UtilitiesService {
    constructor(
        private http: HttpClient,
        private configurationService: ConfigurationService
    ) { }

    register(registerModel: any) {
        return this.http.post(this.configurationService.apiUrl + '/site/register', JSON.stringify(registerModel));
    }
    contact(contactModel: any) {
        return this.http.post(this.configurationService.apiUrl + '/site/contact', JSON.stringify(contactModel));
    }

    resetPassword(resetModel: any) {
        return this.http.post(this.configurationService.apiUrl + '/site/reset', JSON.stringify(resetModel));
    }

}
