import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';

@Pipe({ name: 'eval' })
export class EvalStringPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(string) {
    // tslint:disable-next-line:no-eval
    return eval(string);
  }
}
