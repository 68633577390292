import { Grade } from './api.grade';
import { Tag } from './api.tag';
import { Type } from './api.type';
export class Resource {
        title: string;
        url: string;
        description: string;
        type: Type | string;
        related: Resource[] = [];
        tags: Tag[] = [];
        created_tidy: string;
        thumbnail_url: string;
        latest_user_grade: Grade;
        record_id: number;
}
