import { Grade } from './api.grade';
import { Subscription } from './api.subscription';
import { Activity } from './api.activity';
import { UserInfo } from './api.user_info';
export class User {
    username: string;
    email: string;
    grades: Grade[] = [];
    subscriptions: Subscription[] = [];
    activity: Activity[] = [];
    thumbnail_url: string;
    attributes: UserInfo[] = [];
    showcase_attributes: UserInfo[] = [];

}
