import { ResourceType } from './../../models/api.resource_type';
import { ApiService } from './../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {

  public resource_types: ResourceType[] = [];

  constructor(
      private configurationService: ConfigurationService,
      private apiService: ApiService
    ) { }

  ngOnInit() {
    this.configurationService.setTitle('Our Resources');

    this.apiService.resource_types.subscribe((resource_types: ResourceType[]) => {
        this.resource_types = resource_types;
    });

  }

}
