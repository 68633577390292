import { Component } from '@angular/core';
import fontawesome from '@fortawesome/fontawesome';
import {faCog} from '@fortawesome/fontawesome-free-solid';
import {faAddressCard} from '@fortawesome/fontawesome-free-regular';
import {faAccessibleIcon} from '@fortawesome/fontawesome-free-brands';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor() {
        fontawesome.library.add(faCog, faAddressCard, faAccessibleIcon);
        // fontawesome.library.add(far);
        // fontawesome.library.add(fab);
    }
}
